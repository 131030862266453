import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  DSSelectItemKinds,
  DSSelectItemTypes,
  IDSSelectItem,
} from '../../../form';
import { DSIconCategories } from '../../icon';
import { DSPaginationDirective } from '../pagination.directive';

@Component({
  selector: 'm-ocloud-ds-pagination-full-page',
  templateUrl: './pagination-full-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': '"contents"',
  },
})
export class DSPaginationFullPageComponent extends DSPaginationDirective {
  @Input()
  public closeOnSelected = true;

  public isOpen = false;

  public get items(): IDSSelectItem<number>[] {
    return Array.from({ length: this.pages }).map((_, i) => ({
      text: `${i + 1}`,
      value: i,
      kind: DSSelectItemKinds.DEFAULT,
      type: DSSelectItemTypes.CHECKBOX,
      icon: 'tick-square',
      iconCategory: DSIconCategories.BOLD,
    }));
  }

  public get pageText(): string {
    return this.pages > 1 ? 'pages' : 'page';
  }

  public get itemsText(): string {
    const first = this.index * this.itemsPerPage + 1;
    const last = (this.index + 1) * this.itemsPerPage;
    return `${
      this.total > this.itemsPerPage
        ? `${first}-${last > this.total ? this.total : last}`
        : this.total > 0
        ? `1-${this.total}`
        : '1-1'
    } of ${this.total} ${this.total > 1 ? 'items' : 'item'}`;
  }
}
