import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
} from '@angular/core';
import { DSSelectPrefixDirective } from '../../directives';

@Component({
  selector: 'm-ocloud-ds-select-box',
  templateUrl: './select-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': '"contents"',
  },
})
export class DSSelectBoxComponent {
  @ContentChild(DSSelectPrefixDirective)
  public readonly prefixTemplate: DSSelectPrefixDirective | null = null;

  @Input()
  public animate = false;

  @Input()
  public placeHolder = '';

  @Input()
  public text = '';

  @Input()
  public gap = '';

  @Input()
  public customClasses = '';

  @Input()
  public isPlaceholder = false;
}
